import React, { ChangeEvent,useEffect,useMemo, useState } from "react";
import CHEVRON_LEFT_GREY_ICON from "../../assets/icons/chevron-left-grey.svg";
import usePropertyLocations from "../../hooks/use-property-locations";
import Button from "../Button";
import Form from "../Form";
import {
  AsyncDateRange,
  DateRange,
  PropertyConfig,
  Select,
  Occupancy,
} from "../Form/Fields";
import clxs from "./property-search-mobile.module.css";
import { useStorageDate, useStorageNumber, useStorageString } from "../../hooks/use-storage";
import { useQueryParamValue } from "../../hooks/use-query-param";

interface PropertySearchMobileProps {
  viewLayout: string;
  formId: string;
  onSubmit?: (values: any) => void;
  packageOptions?: any; 
  defaultValue?: {
    destination?: string;
    stay?: {
      start: Date | null,
      end: Date | null,
    };
    occupancy?: { adult_count: number; child_count: number; };
    price_package?: string;
  };
  configs: PropertyConfig[];
  disabled?: ("destination" | "stay" | "occupancy" | "price_package")[];
  hidden?: ("destination" | "stay" | "occupancy" | "price_package")[];
  maxAdultCount?: number;
  maxChildCount?: number;
  validationSchema?: any | (() => any),
  propertySlug?: string;
  configMaxCount?: Record<string, number>;
  is_edit_clicked?: boolean;
}

function PropertySearchMobile (props: PropertySearchMobileProps) {
  const { propertyLocations } = usePropertyLocations(),
    {
      viewLayout,
      formId,
      defaultValue: _defaultValue = {},
      disabled: _disabled = [],
      hidden: _hidden = [],
      onSubmit,
      maxAdultCount = 12,
      maxChildCount = 8,
      validationSchema,
      // configs,
      propertySlug = "",
      // configMaxCount,
      packageOptions,
      is_edit_clicked,
    } = props,
    locationOptions = useMemo(
      () => propertyLocations.reduce(
        (compiled, each) => {
          const { name, slug, coming_soon } = each;

          if (coming_soon) {
            return compiled;
          }

          const option = {
            label: name,
            value: slug,
          };

          return [
            ...compiled,
            option,
          ];
        },
        [] as { label: string; value: string }[],
      ),
      [propertyLocations],
    ),
    disabled = useMemo(
      () => new Set(_disabled),
      [_disabled],
    ),
    hidden = useMemo(
      () => new Set(_hidden),
      [_hidden],
    ),
    defaultValue = useMemo(
      () => ({
        ...DEFAULT_VALUE,
        ..._defaultValue,
      }),
      [_defaultValue],
    ),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    {
      setValue: setDestination,
    } = useStorageString("sessionStorage", "destination"),{
      setValue: setCheckinDate,
    } = useStorageDate("sessionStorage", "checkin_date"), {
      setValue: setCheckoutDate,
    } = useStorageDate("sessionStorage", "checkout_date"), 
    {
      setValue: setAdultCount,
    } = useStorageNumber("sessionStorage", "adult_count"), {
      setValue: setChildCount,
    } = useStorageNumber("sessionStorage", "child_count"),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),
    handleOccupancyChange = (e: ChangeEvent<any>) => {
      const target = e.target,
        { adult_count, child_count } = target.value;

        if(!propertySlug) {
          setAdultCount(adult_count);
          setChildCount(child_count);
        }
    },
    handleDestinationChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target,
        destination = target.value;

        //If Check in date not present auto invoke the date selector.
        if(!checkin_date) {
          setShowDateDialog(true);
        }

        setDestination(destination);
    },
    handleStayChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement,
        { start: checkin_date, end: checkout_date } = target.value as any;


      if(!propertySlug) {  
        setCheckinDate(checkin_date);
        setCheckoutDate(checkout_date);
      }
      
    };

    useEffect(() => {
      if(hidden.has("destination") && !checkin_date && is_edit_clicked) {
        setShowDateDialog(true);
      }
    },[is_edit_clicked])

  return (
    <Form
      id={formId}
      defaultValue={defaultValue}
      className={clxs.container}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <div className={clxs.header}>
        <img
          src={CHEVRON_LEFT_GREY_ICON}
          alt="back"
          onClick={() => {
            setShowDateDialog(false);
            onSubmit?.(null);
          }}
          className={clxs.back}
        />
        {!hidden.has("destination") && (
          <Select
            form={formId}
            name="destination"
            options={locationOptions}
            searchable={false}
            disabled={disabled.has("destination")}
            handlechangeparent={handleDestinationChange}
          />
        )}
      </div>
      {!hidden.has("stay") && (
        propertySlug ? ( //Checks if property slug if present i.e. it is a property detail page. 
          <AsyncDateRange
            form={formId}
            name="stay"
            labels={["Check In", "Check Out"]}
            placeholder={["Select Date", "Select Date"]}
            disabled={disabled.has("stay")}
            propertySlug={propertySlug}
            handlechangeparent={handleStayChange}
            isShowCalendarDialog={showDateDialog}
          />
        ) : (
          <DateRange
            form={formId}
            name="stay"
            labels={["Check In", "Check Out"]}
            placeholder={["Select Date", "Select Date"]}
            disabled={disabled.has("stay")}
            handlechangeparent={handleStayChange}
            isShowCalendarDialog={showDateDialog}
          />
        )
      )}
      {!hidden.has("occupancy") && (
        <div>
          <div className={clxs.addGuest}>
            Add guests *
          </div>
          <Occupancy
            form={formId}
            name="occupancy"
            maxAdultCount={maxAdultCount}
            maxChildCount={maxChildCount}
            disabled={disabled.has("occupancy")}
            onOccupancyChange={handleOccupancyChange}
          />
        </div>
      )}
      {/*{viewLayout === "multi_config" ? (
        <div>
          <div className={clxs.addGuest}>
            Add rooms *
          </div>
          <PropertyConfig
            form={formId}
            name="config_args"
            configs={configs}
            propertySlug={propertySlug}
            maxCount={configMaxCount}
          />
        </div>
      ) : <div>&nbsp;</div>}*/}
      {viewLayout === "package" ? (
        <Select
          form={formId}
          label="Package *"
          name="package_slug"
          options={packageOptions}
          searchable={false}
        />
        ) : null}
      <Button
        className={clxs.done}
        type="submit"
      >
        Done
      </Button>
    </Form>
  );
}

export default PropertySearchMobile;

const DEFAULT_VALUE = {
  destination: "india-goa",
  stay: { start: null, end: null },
  occupancy: { adult_count: 2, child_count: 0 },
  config_args: {},
};
