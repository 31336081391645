import React, { useEffect, useMemo, useState } from "react";
import clxs from "./collection-listing.module.css";
import usePathParams from "../../hooks/use-path-params";
import { useQueryParam, useQueryParamValue } from "../../hooks/use-query-param";
import useAnalytics from "../../hooks/use-analytics";
import usePropertyListing from "../../hooks/use-property-listing";
import Sort from "../PropertyListing/Sort/Desktop";
import { Track } from "../AnalyticsManager";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import CollectionsGallery from "../Home/Collections/CollectionsGallery";
import CollectionPropertyItem from "./CollectionPropertyItem/CollectionPropertyItem";
import useStaticCollections from "../Home/Collections/use-static-collections";
import { useScroll } from "../../hooks/use-scroll";
import CollectionPropertySearch from "./CollectionPropertySearch";
import NearbyLocations from "../PropertyListing/NearbyLocations";
import CollectionLoader from "./Collectionloader";
import { useNavigate } from "react-router-dom";
import { getCollectionSearchUrl } from "../../../utils/collection-search-url";
import NoVillasFound from "./NoVillasFound";

function CollectionListing() {
  const [sort, setSort] = useQueryParam("string", "sort"),
    COLLECTIONS_LIST = useStaticCollections(),
    { ratio } = useScroll(),
    navigate = useNavigate(),
    [lengthId, setLengthId] = useState<number>(0),
    collectionSlug = usePathParams(PATH, "collection_slug"),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    search = useQueryParamValue("string", "search"),
    location = useQueryParamValue("string", "location"),
    { track } = useAnalytics(),
    // location_slug = useMemo(
    //   () => `${destination}-${location}`,
    //   [destination, location],
    // ),
    {
      properties,
      propertyCount,
      getNextPage,
      // discount,
      isLoading,
    } = usePropertyListing(
      //location_slug,
      location,
      undefined,
      checkin_date?.toISOString(),
      checkout_date?.toISOString(),
      adult_count,
      child_count,
      search,
      sort,
      collectionSlug,
    ),
    activeCollection = useMemo(() => {
      const collection = COLLECTIONS_LIST.find(
        (oCollection) => oCollection.slug === collectionSlug,
      );

      if (!collection) {
        return COLLECTIONS_LIST[0];
      }
      return collection;
    }, [COLLECTIONS_LIST, collectionSlug]),
    // destinationFriendly = useMemo(
    //   () => {
    //     const friendly = propertyLocations
    //       .find(each => each.slug === location_slug)?.name || "";

    //     return friendly;
    //   },
    //   [propertyLocations, location_slug],
    // ),
    subtitle = useMemo(() => {
      const collectionlocation = location.split("-");
      const selectedLocation = collectionlocation[1];
      if (propertyCount == 0 && !location) {
        return null;
      }

      // If location and propertyCount are true
      if (location && propertyCount && selectedLocation) {
        return `${propertyCount} Luxury Villas with ${activeCollection?.name}`; //in ${selectedLocation}
      }

      // If location is falsy but propertyCount is true
      if (!location && propertyCount) {
        return `${propertyCount} Luxury Villas with ${activeCollection?.name}`;
      }

      // If location is true but propertyCount is false or not provided
      if (location) {
        return "";
      }

      // Default case, should ideally never reach here based on the given conditions
      return `${propertyCount} Luxury Villas with ${activeCollection?.name}`;
    }, [propertyCount, activeCollection?.name, location]),
    handleRatioChange = (ratio: number) => {
      if (ratio < 0.6) {
        return;
      }

      if (lengthId === properties.length) {
        return;
      }

      setLengthId(properties.length);
    },
    handleSortChange = (nextDirection: string) => {
      track("property_listing_sort_clicked", { sort: nextDirection });

      return setSort(nextDirection);
    },
    handleCollectionClick = (collection_slug: string) => {
      const url = getCollectionSearchUrl(
        collection_slug,
        checkin_date,
        checkout_date,
        adult_count,
        child_count,
        location,
      );

      navigate({ pathname: url }, { replace: true });
    },
    handleCollectionViewedEvent = () => {
      const trackingPayload = {
        collection_name: activeCollection?.name,
      };

      track("collection_viewed", trackingPayload);
    };

  useEffect(() => handleRatioChange(ratio), [ratio]);

  useEffect(() => {
    if (!lengthId) {
      return;
    }

    getNextPage();
  }, [lengthId]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [collectionSlug]);

  useEffect(() => handleCollectionViewedEvent(), []);  

  return (
    <>
      <div className={clxs.container}>
        <CollectionLoader isLoading={isLoading}></CollectionLoader>

        <CollectionPropertySearch
          className={clxs.search}
          filterMobileClassName={clxs.search}
          filterDesktopClassName={clxs.search}
          propertyCount={propertyCount}
          sort={sort}
          onSortChange={handleSortChange}
          activeCollection={activeCollection}
        />

        {/* IF no property present and properties are loaded then show no villas found */}
        {(!propertyCount && !isLoading) ? (
          <NoVillasFound />
        ) : (
          <>
            <div className={clxs.titleContainer}>
              <div className={clxs.title}>{subtitle}</div>
              <Sort
                className={clxs.popularButton}
                value={sort}
                onChange={handleSortChange}
              />
            </div>

            <div className={clxs.properties}>
              {properties.map((each, i) => {
                const decodedLocation = decodeLocationSlug(each.location_slug),
                  { location, destination } = decodedLocation,
                  propertySlug = each.property_slug,
                  href = `/villas/${destination}/${location}/${propertySlug}`,
                  payload: any = {
                    ...each,
                    ...decodedLocation,
                    href: href,
                    index: i,
                  };

                return (
                  <Track
                    key={i}
                    event="property_listing_property_clicked"
                    payload={payload}
                  >
                    <CollectionPropertyItem property={each} />
                  </Track>
                );
              })}
            </div>
          </>
        )}
      </div>

      <div className={clxs.gallery}>
        <CollectionsGallery
          title="Explore More Collections"
          subtitle="Explore other curated collections made for you, highlighting our most exciting homes"
          activeCollection={collectionSlug}
          onCollectionClick={handleCollectionClick}
          className={clxs.galleries}
        />
      </div>

      <NearbyLocations
        locationSlug={location}
        checkinDate={checkin_date}
        checkoutDate={checkout_date}
        adultCount={adult_count}
        childCount={child_count}
        className={clxs.nearbyLocations}
        category="collection"
        title="Explore our Popular Locations"
        subtitle="Explore are best homes in other locations near you."
      />
    </>
  );
}

export default CollectionListing;

const PATH = "/villas/collections/:collection_slug";
