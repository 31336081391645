
const OUTRO_TEMPLATE_NEW: { [key: string]: OutroContent } = {
  "india-goa": {
    header: "<h3 style='margin:0;margin-bottom: 6px;'>Luxury Villas for Rent in Goa: Private Pool & Heritage Charm</h3>",
    description: "It’s not just the Goa you know, it’s more than beautiful beaches and cookie-cutter resorts.We have created a portfolio of opulent holiday villas surrounded by greenery, sea and sand.We have explored Goa’s rich history and heritage and brought it into our private bungalows for rent.\n\nWe have positioned ourselves near the best in food, culture and introduced to Goa the #LohonoLife amidst its subtle charm.All our luxury villas in Goa for rent are situated in the rich but secluded locales of Assagao, Siolim, Vagator, Anjuna, and Dona Paula.However, it is just a drive away from popular restaurants and places.Our holiday villas on rent have a private pool, a lawn and all the amenities you need for a fun and relaxing time away from the city.You can recharge yourselves at our private villas before you go back to your busy schedule.Experience exceptional luxury in Lohono’s Goa.\n\n",
    sections: [
      {
        title: "\n\n<h3 style='margin:0;margin-bottom: 6px;'>Guide to Goa</h3>",
        content: "Nestled along India's western coast, Goa is a sun-kissed paradise renowned for its pristine beaches, vibrant culture, and delectable cuisine. With its laid-back atmosphere and diverse attractions, Goa offers an unforgettable vacation experience for travellers of all ages. From serene beaches and luxury villas in Goa to bustling markets and lively nightlife, this coastal state has something to offer everyone.\n",
      },
      {
        title: "<h3 style='margin:0;margin-bottom: 6px;'>How To Reach Goa</h3>",
        content: "Goa is well-connected by air, rail, and road. The primary airport is Dabolim International Airport, which serves both domestic and international flights. For those travelling by train, Madgaon and Vasco da Gama are the main railway stations. If you prefer a road trip, Goa is well-connected to major cities like Mumbai, Pune, and Bangalore by a network of national highways.\n",
      },
      {
        title: "<h3 style='margin:0;margin-bottom: 6px;'>Places to See in Goa </h3>",
        content: "The entire state is lined by a beach shore, making it an ideal destination for a beach enthusiast. Over the years, popular beaches like Baga, Calangute, Anjuna, Candolim have seen hordes of tourists.\n\nYou can ditch these and visit the lesser-known beaches of Goa like Betalbatim Beach, Kakolem Beach, Betul Beach in a fishing village \n\nFor a taste of history, visit Old Goa, home to the Basilica of Bom Jesus and Se Cathedral, both UNESCO World Heritage Sites. The Latin Quarter in Panjim, with its colourful Portuguese-style houses, is also worth a visit.\n\nNature lovers can explore the Dudhsagar Waterfalls, one of the tallest in India, and the Bhagwan Mahavir Wildlife Sanctuary, where you might spot leopards, elephants, and a variety of bird species.\n",
      },
      {
        title: "<h3 style='margin:0;margin-bottom: 6px;'>Where to Stay in Goa</h3>",
        content: "Looking for luxury villas in Goa? Choose Lohono Stays for a luxurious Goan getaway. Travelling with your furry friend? Opt for our pet-friendly villas in Goa. Stay amidst the ocean with our villas near the beach. Find the best villas in North Goa, heritage Goa villas or Private villas in South Goa. Indulge in various in-house activities like crab catching, gin tasting here.\n",
      },
      {
        title:"<h3 style='margin:0;margin-bottom: 6px;'>Glasshouse On The Bay</h3>",
        content:"Located in Chapora, Glasshouse On The Bay is a stunning two-storied, three-bedroom beach villa in Goa built right on the edge of the water. Its white interiors, flecked with wooden accents, and large floor-to-ceiling windows offer a magnificent view of the sea. Surrounded by coconut and palm trees, this beachfront villa in Goa features two living areas, a dining area, a reading room, a private pool, and a stunning patio where you can watch the sunset and enjoy your meals. It is well-suited for families and small groups of friends.\n\nThis private villa in Goa is also located close to Chapora Fort, the quiet villages of Assagao, and other beaches of North Goa, making it easy to explore the area’s vibrant nightlife or simply relax in your own serene corner.\n",
      },
      {
        title:"<h3 style='margin:0;margin-bottom: 6px;'>Sacri Borod Hill</h3>",
        content:"Lohono Stays offers a collection of luxury villas in Goa with the Sacri Borod Hill, set amidst the hills of Ashwem and Mandrem. These five properties—Sacri Borod Hill 5, 6, 7, 8, 9 are part of an exclusive villa community in Goa. Each villa comes equipped with five ensuite bedrooms, spacious living and outdoor spaces, a private pool, and sun decks, making them ideal for large groups of families and friends. \n\nWhile staying in one of these villas in Goa for stay, you’ll be far from bored. You can visit the beaches of Ashwem and Mandrem, explore the cafes and restaurants in North Goa, or indulge in wellness therapy at nearby spas.\n",
      },
      {
        title:"<h3 style='margin:0;margin-bottom: 6px;'>Villa Supan</h3>",
        content:"Nestled beside the river, Villa Supan is a seven-bedroom property designed in vibrant shades of blue. This private villa in Goa comes with an impressive set of amenities, including a private elevator and jetty, a drop-in hot tub, and a rooftop pool that offers a panoramic view of the lush green landscape with the river flowing past.\n\nAlthough not a beachfront villa in Goa, its riverside location offers tranquillity and serenity, away from the hustle and bustle of the main towns. If you want to explore beyond Villa Supan, you can check out the local restaurants, cafes, and markets in the area.\n",
      },
      {
        title:"<h3 style='margin:0;margin-bottom: 6px;'>Villa Solace</h3>",
        content:"Located in the town of Dona Paula, Villa Solace is a perfect example of a private pool villa in Goa. This four-bedroom property comes with a living and dining area, a balcony, and a stunning rooftop pool overlooking the Arabian Sea. The bedrooms are luxuriously decorated, with elegantly designed furniture in soothing tropical tones of blue, white, and grey.\n\nYou can take the elevator to the rooftop for a relaxing dip in the pool and sip on a cocktail while watching the sunset in your luxury villas in Goa. During the day, explore beaches, try some local cuisine, or even hit up the casinos near your luxury villa in Goa.\n",
      },
      {
        title:"<h3 style='margin:0;margin-bottom: 6px;'>Aurelia Villa D</h3>",
        content:"Chicly designed in soothing tones of white and beige, Aurelia Villa D is an oasis of calm and an excellent choice for a beach villa in Goa. Drawing inspiration from the concept of ‘Vaddo,’ this three-bedroom property fosters community living, sustainability, and social connection. The living and dining areas are enhanced with beautifully arched doors that let in plenty of natural light. The floors are laid with intricate tilework, creating an aesthetic and comfortable space for your getaway to Goa.\n",
      },
      {
        title:"<h3 style='margin:0;margin-bottom: 6px;'>Best Places to Eat in Goa</h3>",
        content:"Beyond the beaches and villas in Goa, it’s also a haven for food lovers. Whether you crave authentic Goan cuisine or international delicacies, the state has it all. For a local experience, head to Fisherman’s Wharf in Panjim or Martin’s Corner in Betalbatim. For a fine dining experience, try the Mediterranean dishes at Gunpowder in Assagao or the Italian fare at Baba Au Rhum, very near to our villas in Assagaon. Don’t forget to visit the numerous beach shacks for fresh seafood, chilled beer, and sunset views, also, very close to our luxury Goa villas.\n\nGoa is a paradise that offers something for everyone—from stunning beaches and vibrant nightlife to luxurious stays and delicious food. Whether you’re looking for a bungalow on rent in Goa or a luxury villa in Goa with a private pool, Lohono Stays provides a curated selection of the best villas in Goa to make your trip unforgettable. Whether it’s a short weekend getaway or an extended vacation, booking a villa with a swimming pool in Goa, sea-facing villa in Goa, or beachfront homes ensures you experience the very best that this beautiful state has to offer.",
      },
    ],
  },
  "india-mahabaleshwar": {
    header: "",
    description:"If you’re craving a getaway filled with beautiful weather and verdant mountains, there is no place better suited for you than Lohono Stays’ luxury bungalows and villas in Mahabaleshwar.We bring you a fantastic collection of private villas for rent to relax in the luxury of this evergreen hill station.\n\nLovely gardens, cozy bedrooms and incredible amenities…Find the perfect blend of luxury in a private villa in Mahabaleshwar and put your mind at ease.Experience luxury and tranquillity as you live #TheLohonoLife at Lohono’s bungalows and villas on rent in Mahabaleshwar.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-bhimtal": {
    header: "",
    description:"Whether it’s a summer getaway or an unforgettable winter vacation, Lohono Stays offers the best villas on rent in Bhimtal.Experience stunning views, natural beauty and incredible adventures, all packed into one.\n\nOur luxury villas for rent in Bhimtal are ideal for rejuvenating get-togethers with family or friends.If your idea of a perfect hill-station retreat includes verdant forests, beautiful hills and a luxurious place to stay in, then make your vacation a reality with us! Look no further and book Lohono Stays’ private villas in Bhimtal.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "maldives-maldives": {
    header: "",
    description: "Get ready for an incredible escape with Lohono Stays.Surrounded by pristine beaches and palm trees, make your dream vacation come true with a stay at Maldives’ private beach villas.\n\nBask in the natural beauty, embrace the vibrant culture and enjoy the luxurious holiday lifestyle at your Maldives luxury villas for rent.Leave all your worries behind and dive into an unforgettable vacation with Maldives’ private resorts filled with a multitude of amenities.When looking for holiday villas in Maldives, look no further and give in to #TheLohonoLife.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "srilanka-sri-lanka": {
    header: "",
    description:"Witness the glory of the 'Pearl of the Indian Ocean’ at Lohono Stays’ luxury villas for rent in Sri Lanka.Incredible natural beauty, scenic vistas, modern amenities and comforts in your very own private villas in Sri Lanka.\n\nLive #TheLohonoLife with fantastic beaches, glistening waters and luxury private beach villas in Sri Lanka.Experience the best of all worlds, from luxury to relaxation and renew your mind, body and soul at Lohono’s holiday villas in Sri Lanka.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-alibaug": {
    header: "",
    description:"Situated just a few kilometres away from Mumbai, lies the beautiful city Alibaug - also called The Hamptons of Mumbai.A haven known for its quaint beaches, natural beauty, craft museums and close proximity to Mumbai.We have chosen the best spots to curate our magnificent & luxury villas and bungalows where you and your loved ones can relax and watch the world go by.\n\nOur private villas are located in Kihim and Zirad which are only 15 minutes from the Jetty.Our luxury villas on rent in Alibaug are equipped with a private swimming pool and everything you may require for a comfortable stay.We aim to ensure that all our guests at our luxury villas have a memorable holiday in Lohono’s Alibaug.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-karjat": {
    header: "",
    description:"An antidote to the mundane city life, Lohono’s villas on rent in Karjat are ideal if you are looking for a quick and luxurious getaway.Karjat is not just any ordinary destination, it’s a destination of hills that will lure you in.\n\nEnjoy the crisp air while sipping some hot cocoa on the balcony of your private Karjat farmhouse.Lohono's villas for rent in Karjat are secluded to ensure privacy and can help you escape to a place where all your needs are taken care of.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-coonoor": {
    header: "",
    description:"Enjoy the pleasant weather of the Nilgiris as you step back in time in charming Coonoor.Located in Tamil Nadu, you can rent the best cottages in Coonoor that are drenched in luxury and provide a home-like experience.\n\nNature lovers can make the most of the verdant environment of the Nilgiri Hills by birdwatching, exploring Coonoor's tea gardens and hiking the beautiful trails lined with wildflowers.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-lonavala": {
    header: "",
    description: "Lonavala is a town defined by rolling green hills, a pleasant climate and ancient rock-cut caves.With activities like trekking, paragliding and fishing, Lonavala is the most sought-after destination to get bungalows on rent for weekend getaways.\n\nOur luxury villas on rent in Lonavala are spectacular retreats nestled in the mighty Sahyadri range in Maharashtra.The crisp air and scenic beauty make it an ideal destination to rent a private villa and spend quality time with your family or friends.Our villas in Lonavala blend comfort and warm hospitality with luxury, giving you a much-needed break, away from the city.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
   "india-mussoorie": {
    header: "",
    description: "Mussoorie has rightfully earned the title of The Queen of Hills, with some of the most promising views that are known to enchant travellers.Rent a private villa in Mussoorie and let the scents of pine and cedar forests fill up your senses while you take in the panoramic views of the green Doon Valley and the distant snow-capped Himalayan peaks.\n\nStay at Lohono’s luxury villas in Mussoorie and let us take care of all the details while you unwind with loved ones and enjoy the many offbeat activities that Mussoorie has to offer.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-kasauli": {
    header: "",
    description:"Nestled in the verdant mountains of Himachal, Lohono Stays brings you the best homestays in Kasauli that promise unforgettable vacations.Immerse yourself in nature and experience true luxury at our best luxury villas for rent in Kasauli.\n\nExplore local sights, historic monuments, and nature trails and return to be pampered at our amazing villa stays in Kasauli.Reconnect with your loved ones on a laid-back, luxe holiday in our private villas in Kasauli with ample spaces to lounge and unwind.Unleash your inner wanderer and make the most of Lohono’s Kasauli.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "india-rishikesh": {
    header: "",
    description:"Set against the backdrop of the mighty Himalayas and surrounded by the natural beauty of the Ganga, the luxury villas in Rishikesh are calling.We bring you the most exotic private villas in Rishikesh for rent, where you can create memories for a lifetime with a large group of friends or family.Pack your bags and book a villa in Rishikesh with Lohono Stays, where you can experience true peace amidst nature.\n\nOpt for a riverside villa in Rishikesh, where you can wake up to the pleasant sounds of the flowing river, or a private pool villa in Rishikesh for some much-needed me-time in the lap of luxury.Our holiday homes in Rishikesh are also well connected to the city, so that you can set off on some thrilling adventures.",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "indonesia-bali": {
    header: "",
    description:"If there is one paradise on earth, Bali is undoubtedly it.With a variety of luxury villas to rent in Bali on the beaches and villas with private pools, find your ideal vacation rentals amidst the island’s incredible natural beauty.\n\nLohono’s private villas in Bali provide everything you need for a fun and relaxing vacation.Come, experience bliss at our luxury villa rentals on this diverse island!",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "thailand-phuket": {
    header: "",
    description:"Coupled with large glass windows and sweeping views of the sea, our luxurious Phuket waterfront properties for rent are a sight to behold.Lohono Stays brings you the perfect blend of luxury and tranquillity in Phuket with beachfront villas and luxury houses for rent.\n\nEnjoy your dream holiday villa in Phuket with a private pool along with exotic beaches, vibrant nightlife and authentic Thai cuisine.Our luxury holiday homes and private villas for rent provide all the amenities for a comfortable stay while you experience the culture, architecture, bustling markets and abundant activities.",

    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
  "thailand-koh-samui": {
    header: "",
    description:"Sunny beaches, luxury villas and blue skies… if this is your dream vacation, then our Koh Samui holiday home rentals are a win!.Whether it’s a villa in Koh Samui with a private pool or a beachfront villa for rent or a blend of both in the form of a beachfront villa with a private pool; the list is endless!\n\nThe charm of the beach villas will lull you into a relaxed state of mind as our world-class villa amenities cater your comfort above all.Your search for the best private villas for rent in Koh Samui ends here!",
    sections: [
      {
        title: "",
        content: "",
      },
    ],
  },
};



export default OUTRO_TEMPLATE_NEW;
